import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Logo from "../assets/img/logo.png";

export default function AppNavBar() {
  const [isMobileNavActive, setMobileNavActive] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [scrollToSection, setScrollToSection] = useState(null);

  const toggleMobileNav = () => {
    setMobileNavActive(!isMobileNavActive);
  };

  const handleSectionClick = (sectionId) => {
    scrollToTop(); 
    setActiveSection(sectionId);
    handleScrollToSection(sectionId);
  };

  const handleScrollToSection = (sectionId) => {
    if (location.pathname !== "/") {
      history.push("/");
      setScrollToSection(sectionId);
    } else {
      setScrollToSection(sectionId);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const detectActiveSection = () => {
    const position = window.scrollY + 200;

    const sections = [
      "portfolio",
      "about",
      "services",
      "testimonials",
      "announcement",
    ];
    for (const sectionId of sections) {
      const section = document.querySelector(`#${sectionId}`);
      if (section) {
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(sectionId);
          return;
        }
      }
    }
    setActiveSection("");
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("#header");
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add("header-scrolled");
          setShowBackToTop(true);
        } else {
          header.classList.remove("header-scrolled");
          setShowBackToTop(false);
        }
      }
      detectActiveSection();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollToSection) {
      const section = document.querySelector(`#${scrollToSection}`);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth",
        });
      }
      setScrollToSection(null);
    }
  }, [scrollToSection]);

  useEffect(() => {
    const navbar = document.querySelector("#navbar");
    const mobileNavToggle = document.querySelector(".mobile-nav-toggle");

    if (isMobileNavActive) {
      navbar.classList.add("navbar-mobile");
      mobileNavToggle.classList.add("bi-x");
      mobileNavToggle.classList.remove("bi-list");

      navbar.style.backgroundColor = "rgba(20, 21, 28, 0.9)";
      navbar.style.position = "fixed";
      navbar.style.top = "0";
      navbar.style.right = "0";
      navbar.style.left = "0";
      navbar.style.bottom = "0";
      navbar.style.zIndex = "999";
    } else {
      navbar.classList.remove("navbar-mobile");
      mobileNavToggle.classList.remove("bi-x");
      mobileNavToggle.classList.add("bi-list");

      navbar.style.backgroundColor = "";
      navbar.style.position = "";
      navbar.style.top = "";
      navbar.style.right = "";
      navbar.style.left = "";
      navbar.style.bottom = "";
      navbar.style.zIndex = "";
    }
  }, [isMobileNavActive]);

  useEffect(() => {
    const pathToSection = {
      "/contact": "contact",
      "/privacy-policy": "privacy-policy",
      "/": "",
    };

    const currentSection = pathToSection[location.pathname] || "";
    setActiveSection(currentSection);

    if (location.pathname === "/") {
      detectActiveSection();
    }
  }, [location.pathname]);

  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center header-transparent"
    >
      <div className="container d-flex align-items-center justify-content-between">
        <NavLink className="navbar-brand logo-nav" to="/">
          <img src={Logo} alt="NewLeaf" className="img-fluid" />
        </NavLink>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <span
                className={`nav-link scrollto ${
                  activeSection === "portfolio" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("portfolio")}
              >
                <i className="ri-git-repository-line"></i>
              </span>
            </li>
            <li>
              <span
                className={`nav-link scrollto ${
                  activeSection === "about" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("about")}
              >
                About
              </span>
            </li>
            <li>
              <span
                className={`nav-link scrollto ${
                  activeSection === "services" ? "active" : ""
                }`}
                onClick={() => handleSectionClick("services")}
              >
                Services
              </span>
            </li>
            <li className="dropdown">
              <span
                className={`nav-link scrollto ${
                  activeSection === "explore" ? "active" : ""
                }`}
                role="button"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                More <i className="ms-2 bi bi-chevron-down"></i>
              </span>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <span
                    className={`nav-link scrollto ${
                      activeSection === "testimonials" ? "active" : ""
                    }`}
                    onClick={() => handleSectionClick("testimonials")}
                  >
                    Testimonials
                  </span>
                </li>
                <li>
                  <span
                    className={`nav-link scrollto ${
                      activeSection === "announcement" ? "active" : ""
                    }`}
                    onClick={() => handleSectionClick("announcement")}
                  >
                    Announcement
                  </span>
                </li>
                <li>
                  <NavLink
                    className={`nav-link ${
                      activeSection === "privacy-policy" ? "active" : ""
                    }`}
                    to="/privacy-policy"
                    onClick={() => scrollToTop()} 
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                className={`nav-link ${
                  activeSection === "contact" ? "active" : ""
                }`}
                to="/contact"
                onClick={() => scrollToTop()} 
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={toggleMobileNav}
          ></i>
        </nav>
        <button
          className={`back-to-top d-flex align-items-center justify-content-center ${
            showBackToTop ? "show" : "hidden"
          }`}
          onClick={(e) => {
            e.preventDefault();
            scrollToTop();
          }}
        >
          <i className="bi bi-arrow-up-short"></i>
        </button>
      </div>
    </header>
  );
}
