import { useState } from 'react';

const useModalManager = () => {
  const [modalInfo, setModalInfo] = useState({ show: false, modalId: '' });
  const [activeTab, setActiveTab] = useState('infoModal-1');

  const openModal = (modalId) => {
    setModalInfo({ show: true, modalId });
    setActiveTab(modalId);
  };

  const closeModal = () => {
    setModalInfo({ show: false, modalId: '' });
  };

  document.activeElement.blur();
  return {
    modalInfo,
    activeTab,
    openModal,
    closeModal,
  };
};

export default useModalManager;