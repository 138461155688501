import { Fragment } from "react";
import About from "../components/homePage/About";
import Banner from "../components/homePage/Banner";
import Bookshelf from "../components/homePage/Bookshelf";
import Services from "../components/homePage/Services";
import Testimonials from "../components/homePage/Testimonials";
import Announcement from "../components/homePage/Announcement";

export default function Home() {
  return (
    <Fragment>
      <Banner />
      <Bookshelf id="portfolio" />
      <Announcement id="announcement" />
      <About id="about" />
      <Services id="services" />
      <Testimonials id="testimonials" />
    </Fragment>
  );
}
