import React from 'react';
import { Link } from 'react-router-dom'; 
import 'boxicons/css/boxicons.min.css'; 

function NotFound() {
  return (
    <div className="not-found-wrapper">
      <div className="not-found content">
        <i className='bx bx-sad bx-lg' style={{ fontSize: '5rem', color: '#555' }}></i>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you're looking for doesn't exist.</p>
        
        <Link to="/" className="go-back-icon">
          <i className='bx bx-left-arrow-circle' style={{ fontSize: '3rem', color: '#0b9595' }}></i>
        </Link>
      </div>
    </div>
  );
}


export default NotFound;
