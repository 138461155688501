import React from "react";
import adGif from "../assets/img/modal/announce.gif";
import nextArrowGif from "../assets/img/modal/nextIcon.gif";

const Modal = ({ show, onClose }) => {
  const handleNextClick = () => {

    onClose();

    const announcementSection = document.querySelector("#announcement");
    if (announcementSection) {
      announcementSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!show) return null; 

  return (
    <div className="advertisement">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close-button" onClick={onClose}>
            &times;
          </button>

          <img src={adGif} alt="Ad GIF" className="modal-gif" />

          <button className="next-arrow-gif" onClick={handleNextClick}>
            <img src={nextArrowGif} alt="Next Arrow" style={{ width: '100%', height: '100%' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
